/**
 * エラーメッセージのマップです
 */
export const MESSAGES = require('../data/messages.json');

/**
 * バリデーションエラーメッセージのマップです
 */
export const VALIDATION_MESSAGES = require('../data/validationMessages.json');

/**
 * auID認可チケットのクッキー名です
 */
export const AU_ID_AUTH_TICKET_COOKIE_NAME = 'CCAAUTKT';

/**
 * トスアップ受付店コードのクッキー名です
 */
export const TOS_ACC_SHOP_CD_COOKIE_NAME = 'tos_acc_shop_cd';

/**
 * トスアップ担当者IDのクッキー名です
 */
export const TOS_USER_ID_COOKIE_NAME = 'tos_user_id';

/**
 * トスアップ受付店名称のクッキー名です
 */
export const TOS_SHOPNM_COOKIE_NAME = 'tos_shopnm';

/**
 * 特設ページ代理店コードのクッキー名です
 */
export const SPECIAL_PAGE_AGENCY_CODE_COOKIE_NAME = 'AG_CD';

/**
 * メールアドレス入力時のautocomplete候補ドメインです
 */
export const MAIL_DOMAIN_CANDIDATE = [
  'au.com',
  'ezweb.ne.jp',
  'gmail.com',
  'icloud.com',
  'me.com',
  'yahoo.co.jp',
  'docomo.ne.jp',
  'i.softbank.jp',
  'softbank.ne.jp',
];

/**
 * 申込種別
 */
export const CONTRACT_TYPE = {
  /** 新規契約 */
  NEW: 'NEW',
  /** MNP */
  MNP: 'MNP',
  /** 機種変更 */
  CHANGE: 'CHANGE',
  /** 新規(オープン) */
  NEWOPEN: 'NEWOPEN',
  /** 番号移行 */
  MIGRATION: 'MIGRATION',
};

/**
 * 受取方法
 */
export const RECEIVE_TYPE = {
  /** 店舗受取 */
  SHOP: 'SHOP',
  /** 自宅受取 */
  HOME: 'HOME',
};

/**
 * 受付ブランド
 */
export const RECEPTION_BRAND = {
  /** au */
  AU: 'AU',
  /** UQ mobile */
  UQ_MOBILE: 'UQ_MOBILE',
};
